import "./App.css";
import Navbar from "./Components/Navbar";
import About from "./Components/About";
import Portfolio from "./Components/Portfolio";
import Footer from "./Components/Footer";

function App() {
  return (
    <>
      <Navbar />
      <About />
      <Portfolio />
      <Footer />
    </>
  );
}

export default App;
